/*****************************************************************************
 * UmanIT - Slider des thumbnails Univers Gourmand (homepage)
 *****************************************************************************/

require('slick-carousel');

const thumbnailsSlider = $(".gourmand-universe__thumbnails");
let thumbnailSlide = $(".gourmand-universe__thumbnail");
let index;
let isInit = false;

// Initialisation
handleThumbnailsSlider();

// Changement de thumbnail active
if(thumbnailSlide) {
  $(thumbnailSlide).each(function () {
    if(window.innerWidth < 992 ) {
      $(this).click(function() {

        const oldIndex = index;
        hideSlide();
        showSlide(oldIndex);
      });
    }
  });
}

// Resize de la fenêtre
window.addEventListener("resize", function() {
  handleThumbnailsSlider();
});

/**
 * Construction du slider en mobile (et destruction en desktop
 * -> https://github.com/kenwheeler/slick/issues/542#issuecomment-168658956
 * return {void}
 */
function handleThumbnailsSlider() {
  if(thumbnailsSlider) {
    if(breakpoints.isMobile() && !isInit) {
      thumbnailsSlider.slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        mobileFirst: true,
        centerPadding: "0px",
        focusOnSelect: true,
        arrows: false,
        infinite: false
      });

      isInit = true;
    } else if(breakpoints.isDesktop() && isInit) {
      thumbnailsSlider.slick('unslick');
      isInit = false;
    }

    hideSlide();
  }

}

/**
 * Cacher la thumbnail active du slider
 *
 * return {index} l'index de la slide active
 */
function hideSlide() {
  const slideToHide = thumbnailsSlider.find(".gourmand-universe__thumbnail.active").parents(".slick-slide");
  index = $(slideToHide).data("slick-index");

  $(slideToHide).hide();

  return index;
}

/**
 * Montrer l'ancienne slide cachée
 *
 * @param oldIndex
 *
 * return {void}
 */
function showSlide(oldIndex) {
  const slideToShow = thumbnailsSlider.find(".slick-slide[data-slick-index='" + oldIndex + "']");

  $(slideToShow).show();
}
